import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
	BasicStorage,
	ChatMessage,
	ChatProvider,
	IStorage,
	MessageContentType,
	Presence,
	UpdateState,
	User,
	UserStatus,
} from "@chatscope/use-chat";
import { SnikketChatService } from "./SnikketChatService";
import { Chat } from "./components/Chat";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import * as snikket from "snikket-sdk";
import localForage from "localforage";
import * as Sentry from "@sentry/browser";
import GitInfo from "react-git-info/macro";
import { stem } from "porter2";

Sentry.init({
	dsn: "https://ec9ae5aef68e4035937d4c5943c4156c@app.glitchtip.com/7125",

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],

	tracesSampleRate: 0.0,
	tracePropagationTargets: [],

	replaysSessionSampleRate: 0.0,
	replaysOnErrorSampleRate: 1.0,
});

(window as any).snikket = snikket;

(window as any).mylog = [];
window.addEventListener("error", (err) => {
	(window as any).mylog.push("ERROR: " + err.message);
});

const pwa = window.matchMedia("(display-mode: standalone)").matches;
const ios = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
const webkit =
	navigator.userAgent.match(/AppleWebKit/) &&
	!navigator.userAgent.match(/Chrom/);

// sendMessage and addMessage methods can automagically generate id for messages and groups
// This allows you to omit doing this manually, but you need to provide a message generator
// The message id generator is a function that receives message and returns id for this message
// The group id generator is a function that returns string
const messageIdGenerator = (message: ChatMessage<MessageContentType>) =>
	nanoid();
const groupIdGenerator = () => nanoid();

const storage = new BasicStorage({ groupIdGenerator, messageIdGenerator });

const services = {};
const serviceFactory =
	(xmppClient: snikket.Client) =>
	(storage: IStorage, updateState: UpdateState) => {
		if (services[xmppClient.accountId()]) {
			services[xmppClient.accountId()].storage = storage;
			services[xmppClient.accountId()].updateState = updateState;
			return services[xmppClient.accountId()];
		} else {
			return (services[xmppClient.accountId()] = new SnikketChatService(
				xmppClient,
				storage,
				updateState,
			));
		}
	};

let opfs = false;
let persistence = null;
let mediaStore = snikket.persistence.MediaStoreCache("snikket");
localForage.config({ name: "snikket" });
localForage.ready().then(() => {
	const idbp = snikket.persistence.IDB("snikket", mediaStore, null, stem);
	if (navigator.storage?.getDirectory && (webkit || ios)) {
		persistence = new snikket.persistence.Sqlite("snikket.sqlite3", mediaStore);
		mediaStore.setKV(idbp); // Use IDB for media KV for now because we can't use opfs-sahpool from service worker
		opfs = true;
	} else {
		persistence = idbp;
	}
	(window as any).persistence = persistence;
	(window as any).mediaStore = mediaStore;
});

function App() {
	const state = storage.getState();
	const [xmppClient, setXmppClient] = useState(undefined);
	const [needPassword, setNeedPassword] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [connectionFailed, setConnectionFailed] = useState(false);
	const [loading, setLoading] = useState(navigator.onLine);

	useEffect(() => {
		(async () => {
			if (navigator.onLine) setTimeout(() => setLoading(false), 1000);
			if (opfs) {
				const result = await persistence.db.exec(
					"SELECT account_id as accountId, display_name AS fn, token IS NOT NULL AS hasToken, sm_state IS NOT NULL AS hasSession FROM accounts",
				);
				setAccounts(result.array);
			} else {
				const keys = await localForage.keys();
				const accounts = keys
					.filter((k) => k.startsWith("login:clientId:"))
					.map((k) => k.substring(15));
				setAccounts(
					await Promise.all(
						accounts.map(async (account) => {
							const fn = await localForage.getItem(`fn:${account}`);
							return {
								accountId: account,
								fn: fn,
								hasToken: keys.includes(`login:token:${account}`),
								hasSession: keys.includes(`sm:${account}`),
							};
						}),
					),
				);
			}
		})();
	}, []);

	useEffect(() => {
		if (!navigator.serviceWorker) return;
		const handler = (event) => {
			if (event.data.event === "notificationclick") {
				(window as any).mylog.push("NOTIF: " + JSON.stringify(event.data.data));
				console.log("NOTIF", event.data.data, xmppClient);
				if (xmppClient && event.data.data.accountId !== xmppClient.accountId())
					return;
				if (!xmppClient) login(event.data.data.accountId);
				storage.setActiveConversation(event.data.data.chatId);
				if (event.data.data.type === snikket.MessageType.MessageCall) {
					services[event.data.data.accountId]?.stopCallRinging();
					if (event.data.data.callStatus === "propose") {
						setTimeout(
							() =>
								services[event.data.data.accountId]?.acceptCall(
									event.data.data.chatId,
								),
							100,
						);
					}
					services[event.data.data.accountId]?.doStateUpdate();
				} else {
					services[event.data.data.accountId]?.jumpTo(
						event.data.data.chatId,
						event.data.data.messageId,
						event.data.data.timestamp,
					);
				}
			}
			if (event.data.event === "notificationclose") {
				console.log("NOTIFCLOSE", event.data.data);
				if (event.data.data.type === snikket.MessageType.MessageCall) {
					services[event.data.data.accountId]?.stopCallRinging();
				}
			}
		};
		navigator.serviceWorker.addEventListener("message", handler);

		return () =>
			navigator.serviceWorker.removeEventListener("message", handler);
	}, [xmppClient]);

	function login(accountId: string) {
		setLoading(navigator.onLine);
		const newXmppClient = new snikket.Client(accountId, persistence);
		newXmppClient.addPasswordNeededListener((_) => {
			setNeedPassword(true);
		});
		storage.addUser(
			new User({
				id: accountId,
				presence: new Presence({
					status: UserStatus.Unavailable,
					description: "",
				}),
				firstName: "",
				lastName: "",
				username: newXmppClient.displayName(),
				email: "",
				avatar: "",
				bio: "",
			}),
		);
		storage.setCurrentUser(storage.getUser(accountId)[0]);

		newXmppClient.addStatusOnlineListener(() => {
			setTimeout(() => setLoading(false), 1000);
		});

		newXmppClient.addConnectionFailedListener(() => {
			setConnectionFailed(true);
		});

		/*window.addEventListener("visibilitychange", (event) => {
			if (document.visibilityState === "hidden") {
				newXmppClient.setNotInForeground();
			} else {
				newXmppClient.setInForeground();
			}
		});*/
		setXmppClient(newXmppClient);
	}

	function usePassword(e) {
		xmppClient.usePassword(e.target.password.value);
		setNeedPassword(false);
	}

	if (connectionFailed) {
		return <h1>connection failed, is your server up and supported?</h1>;
	} else if (needPassword) {
		return (
			<>
				<h1>Login</h1>
				<form action="#" onSubmit={usePassword}>
					<input
						name="jid"
						type="text"
						placeholder="Jabber ID"
						value={state.currentUser.id}
						disabled
					/>
					<input
						name="password"
						type="password"
						placeholder="Password"
						autoFocus={true}
						autoComplete="current-password"
					/>
					<button>Continue</button>
				</form>
			</>
		);
	} else if (state.currentUser) {
		return (
			<ChatProvider
				serviceFactory={serviceFactory(xmppClient)}
				storage={storage}
				config={{
					typingThrottleTime: 500,
					typingDebounceTime: 30000,
					debounceTyping: true,
				}}
			>
				{loading ? (
					<div>
						<div className="fullscreen lds-spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : (
					<Chat persistence={persistence} />
				)}
			</ChatProvider>
		);
	} else if (loading) {
		return (
			<div>
				<div className="fullscreen lds-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	} else if (!pwa && ios) {
		return (
			<section id="ios">
				<h1>Snikket SDK Demo App</h1>
				<p>
					This is an early prototype demo. It works best if you install it to
					your homescreen:
				</p>
				<img
					src="/safari_toolbar.webp"
					alt="Select share from Safari toolbar"
				/>
				<img
					src="/safari_menu.webp"
					alt="Select add to Home Screen from Safari menu"
				/>
				<img src="/safari_add.webp" alt="Select add button in top right" />
			</section>
		);
	} else {
		return (
			<>
				<section id="login">
					<h1>Connect</h1>
					<ul>
						{accounts.map((account) => (
							<li onClick={() => login(account.accountId)}>
								{account.fn} (
								{account.accountId +
									(account.hasSession ? ", session" : "") +
									(account.hasToken ? ", token" : "")}
								){" "}
								<button
									onClick={(e) => {
										e.stopPropagation();
										persistence.removeAccount(account.accountId, true);
										setAccounts(
											accounts.filter((a) => a.accountId !== account.accountId),
										);
									}}
								>
									X
								</button>
								<button
									onClick={(e) => {
										e.stopPropagation();
										persistence.storeStreamManagement(account.accountId, null);
									}}
								>
									s
								</button>
							</li>
						))}
					</ul>
					{accounts.length > 0 && <p>or other</p>}
					<form
						action="#"
						onSubmit={(e) => {
							e.preventDefault();
							login((e.target as any).jid.value);
						}}
					>
						<input name="jid" type="text" placeholder="Jabber ID" />
						<button>Continue</button>
					</form>
				</section>
				<button
					onClick={async () => {
						const dir = await navigator.storage.getDirectory();
						dir.removeEntry(".opfs-sahpool", { recursive: true });
					}}
				>
					nuke it
				</button>
				<h1>Snikket SDK Demo App</h1>
				<p>
					This is a demo of the current prototype of{" "}
					<a href="https://snikket.org/blog/state-of-snikket-2023-the-apps/#the-future-of-building-snikket-apps">
						Snikket SDK
					</a>
					. The UI is mostly slapped together in order to be able to demonstrate
					all of the capabilities of the SDK. While it is a working client, it
					is under heavy development with most focus on the SDK and not the app
					itself.
				</p>
				<section>
					<h1>Is this safe?</h1>
					<p>
						This app is highly experimental and under heavy development.
						Nevertheless, at least one person uses it as their full time desktop
						client and has had no bad events so far. Data loss is very unlikely.
						Connections are made via websockets directly to your server with no
						proxy. Since this is not a standard feature of XMPP servers you may
						not be able to use the client because of that, but at least all
						Snikket instances should work. This code is only tested against
						Snikket instances and similarly configured prosody, and may behave
						more erattically in other contexts.
					</p>
					<p>
						This app stores possibly sensitive data, including message history,
						on your computer. Do not use this client from devices you do not
						trust, such as library computers, etc.
					</p>
				</section>
				<section>
					<h1>Is this going to become Snikket Web and/or Cheogram Web?</h1>
					<p>
						Maybe, but that is not the primary focus at this time, the primary
						focus is on SDK developement.
					</p>
				</section>
				<section>
					<h1>Why release this now?</h1>
					<p>
						Because we need testing and feedback about the behaviour in order to
						find bugs and missing features in the SDK.
					</p>
				</section>
				Any and all questions are welcome in the Sopranica discussion room.
				AGPLv3,{" "}
				<a
					href={
						"https://git.singpolyma.net/snikket-react/tree/" +
						GitInfo().commit.hash
					}
				>
					source code
				</a>
			</>
		);
	}
}

export default App;
