import { IChatService, MessageStatus } from "@chatscope/use-chat";
import {
	ChatEventType,
	MessageContentType,
	MessageDirection,
} from "@chatscope/use-chat";
import {
	ConversationId,
	ChatEventHandler,
	Conversation,
	ConversationRole,
	Participant,
	Presence,
	SendMessageServiceParams,
	SendTypingServiceParams,
	Sender,
	SenderType,
	UpdateState,
	User,
	UserPresenceChangedEvent,
	UserStatus,
} from "@chatscope/use-chat";
import { MessageGroup } from "@chatscope/use-chat/dist/MessageGroup";
import { IStorage } from "@chatscope/use-chat";
import { ChatEvent, MessageEvent, UserTypingEvent } from "@chatscope/use-chat";
import { ChatMessage } from "@chatscope/use-chat";
import * as localForage from "localforage";
import { uuidv7 } from "uuidv7";

import * as snikket from "snikket-sdk";

type EventHandlers = {
	onMessage: ChatEventHandler<
		ChatEventType.Message,
		ChatEvent<ChatEventType.Message>
	>;
	onConnectionStateChanged: ChatEventHandler<
		ChatEventType.ConnectionStateChanged,
		ChatEvent<ChatEventType.ConnectionStateChanged>
	>;
	onUserConnected: ChatEventHandler<
		ChatEventType.UserConnected,
		ChatEvent<ChatEventType.UserConnected>
	>;
	onUserDisconnected: ChatEventHandler<
		ChatEventType.UserDisconnected,
		ChatEvent<ChatEventType.UserDisconnected>
	>;
	onUserPresenceChanged: ChatEventHandler<
		ChatEventType.UserPresenceChanged,
		ChatEvent<ChatEventType.UserPresenceChanged>
	>;
	onUserTyping: ChatEventHandler<
		ChatEventType.UserTyping,
		ChatEvent<ChatEventType.UserTyping>
	>;
	onCallAudioTrack: (data: {
		chatId: String;
		track: MediaStreamTrack;
		streams: Array<MediaStream>;
	}) => void;
	[key: string]: any;
};

export class SnikketChatService implements IChatService {
	storage?: IStorage;
	updateState: UpdateState;
	xmppClient?: snikket.Client;
	callNotification: Notification;
	audioNotification: HTMLAudioElement;
	callNotificationHandled: boolean = false;
	stateTimeout?: ReturnType<typeof setTimeout> = null;
	loadingMessagesFor: Set<String> = new Set();

	eventHandlers: EventHandlers = {
		onMessage: () => {},
		onConnectionStateChanged: () => {},
		onUserConnected: () => {},
		onUserDisconnected: () => {},
		onUserPresenceChanged: () => {},
		onUserTyping: () => {},
		onCallAudioTrack: () => {},
	};

	constructor(xmppClient: any, storage: IStorage, update: UpdateState) {
		this.xmppClient = xmppClient;
		(window as any).xmppClient = xmppClient;

		this.xmppClient.addCallRingListener((session, chatId) => {
			// Give time for push to probably come in first
			setTimeout(() => this.showCallNotification(session, chatId), 500);
		});

		this.xmppClient.addCallRetractListener((chatId: string) => {
			this.stopCallRinging(chatId);
		});

		this.xmppClient.addCallRingingListener((_) => {
			this.doStateUpdate();
		});

		this.xmppClient.addCallMediaListener((session, audio, video) => {
			navigator.mediaDevices
				.getUserMedia({ audio: audio, video: video })
				.then((stream) => {
					session.supplyMedia([stream]);
				})
				.catch(console.error);
		});

		this.xmppClient.addCallTrackListener((chatId, track, streams) => {
			if (track.kind === "audio")
				this.eventHandlers.onCallAudioTrack({ chatId, track, streams });
			if (track.kind === "video") {
				track.addEventListener("mute", () => this.doStateUpdate());
				track.addEventListener("unmute", () => this.doStateUpdate());
			}
			track.addEventListener("ended", () => this.doStateUpdate());
		});

		this.xmppClient.addStatusOnlineListener(() => {
			if (navigator.storage && navigator.storage.persist) {
				navigator.storage.persist();
			}

			this.eventHandlers.onUserPresenceChanged(
				new UserPresenceChangedEvent({
					type: ChatEventType.UserPresenceChanged,
					userId: this.xmppClient.accountId(),
					presence: new Presence({
						status: UserStatus.Available,
						description: "",
					}),
				}),
			);

			(async () => {
				if (window.Notification?.requestPermission)
					await Notification.requestPermission();
				if (!navigator.serviceWorker || !window.Notification?.permission)
					return; // If no service worker support, don't bother with push

				const serviceWorkerRegistration = await navigator.serviceWorker.ready;
				// public key can be computed from private key, but then we need code to do that, so we store both
				let key = (await localForage.getItem("vapid_key")) as {
					publicKey: ArrayBuffer;
					privateKey: ArrayBuffer;
				};
				let keyPair = { publicKey: null, privateKey: null };
				if (key) {
					keyPair.publicKey = await window.crypto.subtle.importKey(
						"raw",
						key.publicKey,
						{
							name: "ECDSA",
							namedCurve: "P-256",
						},
						true,
						["verify"],
					);
					keyPair.privateKey = await window.crypto.subtle.importKey(
						"pkcs8",
						key.privateKey,
						{
							name: "ECDSA",
							namedCurve: "P-256",
						},
						true,
						["sign"],
					);
				} else {
					keyPair = await window.crypto.subtle.generateKey(
						{
							name: "ECDSA",
							namedCurve: "P-256",
						},
						true,
						["sign", "verify"],
					);
					key = {
						publicKey: await window.crypto.subtle.exportKey(
							"raw",
							keyPair.publicKey,
						),
						privateKey: await window.crypto.subtle.exportKey(
							"pkcs8",
							keyPair.privateKey,
						),
					};
					await localForage.setItem("vapid_key", key);
				}
				this.xmppClient.subscribePush(
					serviceWorkerRegistration,
					"webpush@bots.cheogram.com",
					keyPair,
					144,
				);
			})();

			// Refresh active conversation after sync is done
			const activeConversation = this.storage.getState().activeConversation;
			if (activeConversation) {
				activeConversation.data = {
					...activeConversation.data,
					loadedToEnd: false,
				};
				try {
					this.storage.removeMessagesFromConversation(activeConversation.id);
				} catch (e) {}
				this.loadMessagesBefore(activeConversation.id);
			} else {
				this.doStateUpdate();
			}
		});

		this.xmppClient.addStatusOfflineListener(() => {
			this.eventHandlers.onUserPresenceChanged(
				new UserPresenceChangedEvent({
					type: ChatEventType.UserPresenceChanged,
					userId: this.xmppClient.accountId(),
					presence: new Presence({
						status: UserStatus.Unknown,
						description: "",
					}),
				}),
			);
			this.doStateUpdate();
		});

		this.xmppClient.addChatsUpdatedListener((chats) => {
			chats.forEach((chat) => {
				// Add users if not present
				chat.getParticipants().forEach((userId) => {
					storage.addUser(
						new User({
							id: userId,
							presence: new Presence({
								status: UserStatus.Unknown,
								description: "",
							}),
							firstName: "",
							lastName: "",
							username: userId,
							email: "",
							avatar: null,
							bio: "",
						}),
					);

					const [user] = storage.getUser(userId);
					const participant = chat.getParticipantDetails(userId);
					user.username = participant.displayName;
					user.avatar = participant.photoUri ?? participant.placeholderUri;
					user.data = participant;
				});

				if (chat.uiState === snikket.UiState.Closed) {
					storage.removeConversation(chat.chatId, true);
				} else {
					// Add conversation if not present
					if (
						!storage.addConversation(
							new Conversation({
								id: chat.chatId,
								participants: chat.getParticipants().map(
									(p) =>
										new Participant({
											id: p,
											role: new ConversationRole([]),
										}),
								),
								description: chat.getDisplayName(),
								data: { chat: chat },
							}),
						)
					) {
						const [conversation] = storage.getConversation(chat.chatId);
						chat.getParticipants().forEach((p) =>
							conversation.addParticipant(
								new Participant({
									id: p,
									role: new ConversationRole([]),
								}),
							),
						);
					}
					console.log("UNREAD set", chat.chatId, chat.unreadCount());
					this.storage.setUnread(chat.chatId, chat.unreadCount());
				}

				if (
					this.storage.getState().activeConversation?.id === chat.chatId &&
					(this.storage.getState().messages[chat.chatId]?.[0]?.messages
						?.length ?? 0) < 1
				) {
					this.loadMessagesBefore(chat.chatId);
				}
			});

			var allChats = this.xmppClient.getChats();
			storage
				.getState()
				.conversations.sort(
					(x, y) =>
						allChats.findIndex((c) => c.chatId === x.id) -
						allChats.findIndex((c) => c.chatId === y.id),
				);

			this.doStateUpdate();
		});

		this.xmppClient.addUserStateListener((userId, chatId, threadId, state) => {
			this.eventHandlers.onUserTyping(
				new UserTypingEvent({
					userId: userId,
					conversationId: chatId,
					isTyping: state === snikket.UserState.Composing,
					content: null,
				}),
			);
		});

		this.xmppClient.addChatMessageListener((message, eventType) => {
			let status = MessageStatus.DeliveredToCloud;
			if (message.status === snikket.MessageStatus.MessagePending)
				status = MessageStatus.Sent;
			if (message.status === snikket.MessageStatus.MessageDeliveredToServer)
				status = MessageStatus.DeliveredToCloud;
			if (message.status === snikket.MessageStatus.MessageDeliveredToDevice)
				status = MessageStatus.DeliveredToDevice;
			if (message.status === snikket.MessageStatus.MessageFailedToSend)
				status = MessageStatus.Pending;
			const chatMessage = new ChatMessage({
				id: message.serverId
					? message.serverId
					: message.isIncoming()
						? uuidv7()
						: message.localId,
				status: status,
				sender: new Sender({ id: message.senderId, type: SenderType.User }),
				direction: message.isIncoming()
					? MessageDirection.Incoming
					: MessageDirection.Outgoing,
				content: { body: message.html() },
				contentType: MessageContentType.TextHtml,
				data: message as any,
			});

			const [user] = storage.getUser(chatMessage.sender.id);
			if (!user) {
				const participant = this.storage
					.getConversation(message.chatId())?.[0]
					?.data?.chat?.getParticipantDetails(chatMessage.sender.id);
				storage.addUser(
					new User({
						id: chatMessage.sender.id,
						presence: new Presence({
							status: UserStatus.Unknown,
							description: "",
						}),
						firstName: "",
						lastName: "",
						username: participant.displayName,
						email: "",
						avatar: participant.photoUri ?? participant.placeholderUri,
						bio: "",
						data: participant,
					}),
				);
			}

			var updated = false;
			this.storage.getState().messages[message.chatId()]?.forEach((g) => {
				const [existingMessage, idx] = g.getMessage(message.serverId);
				if (existingMessage && message.serverId) {
					g.replaceMessage(chatMessage, idx as number);
					updated = true;
				} else {
					const [existingMessage, idx] = g.getMessage(message.localId);
					if (
						existingMessage &&
						message.localId &&
						(!message.isIncoming() || message.versions.length > 0)
					) {
						g.replaceMessage(chatMessage, idx as number);
						updated = true;
					}
				}
			});
			if (updated) {
				this.doStateUpdate();
				return;
			}
			if (eventType !== snikket.ChatMessageEvent.DeliveryEvent) return;

			const [conversation] = storage.getConversation(message.chatId());
			if (
				!conversation ||
				(this.storage.getState().messages[message.chatId()] || []).length < 1 ||
				conversation.data?.loadedToEnd
			) {
				console.log(
					"UNREAD msg will incr",
					message.chatId(),
					conversation && conversation.unreadCounter + 1,
				);
				if (conversation)
					conversation.data = { ...conversation.data, loadedToEnd: true };
				this.eventHandlers.onMessage(
					new MessageEvent({
						message: chatMessage,
						conversationId: message.chatId(),
					}),
				);
			} else if (message.isIncoming()) {
				// Not loaded to end so not safe to inject new messages
				// Just increment the unread counter
				console.log(
					"UNREAD incr",
					message.chatId(),
					conversation.unreadCounter + 1,
				);
				this.storage.setUnread(
					message.chatId(),
					conversation.unreadCounter + 1,
				);
			}

			if (message.isIncoming()) {
				if (
					conversation &&
					message.type !== snikket.MessageType.MessageCall &&
					(document.hidden ||
						conversation.id !== storage.getState().activeConversation?.id) &&
					(!conversation.data?.chat?.notificationsFiltered() ||
						(conversation.data?.chat?.notifyMention() &&
							message.text.indexOf(this.xmppClient.displayName()) >= 0) ||
						(conversation.data?.chat?.notifyReply() &&
							conversation.data?.chat?.getParticipantDetails(
								message.replyToMessage.senderId,
							)?.isSelf))
				) {
					this.showNotification(conversation, message);
				}
			} else {
				console.log("UNREAD zero", message.chatId());
				this.storage.setUnread(message.chatId(), 0);
				this.doStateUpdate();
			}
		});
		this.xmppClient.start();

		this.storage = storage;
		this.updateState = update;
	}

	async showNotification(
		conversation: Conversation,
		message: snikket.ChatMessage,
	) {
		if (!window.Notification?.permission) return;

		const tag = this.xmppClient.accountId() + ">" + message.chatId();
		if (navigator.serviceWorker) {
			const serviceWorkerRegistration = await navigator.serviceWorker.ready;
			const notifs = await serviceWorkerRegistration.getNotifications({
				tag: tag,
			});
			for (const notif of notifs) {
				if (notif.data.messageId === (message.serverId || message.localId))
					return;
			}
		}

		const title = "Message from " + message.chatId();
		const options = {
			body: message.text,
			tag: tag,
			timestamp: new Date(message.timestamp).getTime(),
			vibrate: [50, 50, 50],
			requireInteraction: true,
			data: {
				accountId: this.xmppClient.accountId(),
				chatId: message.chatId(),
				messageId: message.serverId || message.localId,
				timestamp: message.timestamp,
			},
		};
		if (navigator.serviceWorker) {
			const serviceWorkerRegistration = await navigator.serviceWorker.ready;
			await serviceWorkerRegistration.showNotification(title, options);
		} else {
			const notif = new Notification(title, options);
			notif.addEventListener("click", (event) => {
				this.jumpTo(
					message.chatId(),
					message.serverId || message.localId,
					message.timestamp,
				);
			});
			conversation.data = {
				...conversation.data,
				notifications: conversation.data?.notifications || [],
			};
			conversation.data.notifications.push(notif);
		}
	}

	accountId() {
		return this.xmppClient.accountId();
	}

	doStateUpdate() {
		if (this.stateTimeout) clearTimeout(this.stateTimeout);
		this.stateTimeout = setTimeout(this.updateState, 100);
	}

	async showCallNotification(session: snikket.jingle.Session, chatId: string) {
		if (!Notification?.permission) return;

		const tag = this.xmppClient.accountId() + ">" + chatId + ">call";
		if (navigator.serviceWorker) {
			const serviceWorkerRegistration = await navigator.serviceWorker.ready;
			const notifs = await serviceWorkerRegistration.getNotifications({
				tag: tag,
			});
			if (notifs.length > 0) return; // Already notified, probably from push
			// We could check session id here, but we only do call control per chat right now in UI anyway
		}
		const title = "Incoming Call";
		const options = {
			body: "From " + chatId,
			tag: tag,
			vibrate: [50, 100, 50, 100],
			requireInteraction: true,
			data: {
				accountId: this.xmppClient.accountId(),
				chatId: chatId,
				type: snikket.MessageType.MessageCall,
				callStatus: "propose",
			},
		};
		if (navigator.serviceWorker) {
			navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
				serviceWorkerRegistration.showNotification(title, options);
			});
		} else {
			this.callNotificationHandled = false;
			this.callNotification = new Notification(title, options);

			this.callNotification.addEventListener("click", (event) => {
				session.accept();
				this.stopCallRinging(chatId);
			});
			this.callNotification.addEventListener("close", (event) => {
				this.callNotification = null;
				if (!this.callNotificationHandled) session.hangup();
				this.stopCallRinging(chatId);
			});
		}

		this.doStateUpdate();
		this.startCallRinging();
	}

	startCallRinging() {
		if (this.audioNotification) this.audioNotification.pause();
		this.audioNotification = new Audio("/call.opus");
		this.audioNotification.addEventListener("ended", () =>
			this.audioNotification?.play(),
		);
		this.audioNotification.play();
	}

	stopCallRinging(chatId: string) {
		this.callNotificationHandled = true;
		if (this.audioNotification) this.audioNotification.pause();
		this.audioNotification = null;
		if (this.callNotification) this.callNotification.close();
		if (navigator.serviceWorker) {
			navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
				const notifs = await serviceWorkerRegistration.getNotifications({
					tag: this.accountId() + ">" + chatId + ">call",
				});
				notifs.forEach((notif) => notif.close());
			});
		}
		this.doStateUpdate();
	}

	correctMessage({ editing, message, conversationId }) {
		const data = message.data as any;
		try {
			const msg = data?.replyToMessage
				? data.replyToMessage.reply()
				: new snikket.ChatMessageBuilder();
			msg.localId = message.id;
			msg.threadId = message.data?.threadId;
			for (const attachment of message.data?.attachments || []) {
				msg.addAttachment(attachment);
			}
			if (message.contentType === MessageContentType.TextHtml) {
				msg.setHtml(message.content.body);
			} else {
				msg.text = message.content.body;
			}
			this.storage
				.getConversation(conversationId)[0]
				.data.chat.correctMessage(editing, msg);
		} catch (e) {
			console.error(e);
		}
	}

	sendMessage({ message, conversationId }: SendMessageServiceParams) {
		const data = message.data as any;
		try {
			const msg = data?.replyToMessage
				? data.replyToMessage.reply()
				: new snikket.ChatMessageBuilder();
			msg.localId = message.id;
			msg.threadId = data?.threadId || msg.threadId;
			for (const attachment of data?.attachments || []) {
				msg.addAttachment(attachment);
			}
			if (message.contentType === MessageContentType.TextHtml) {
				msg.setHtml(message.content.body);
			} else {
				msg.text = message.content.body;
			}
			this.storage
				.getConversation(conversationId)[0]
				.data.chat.sendMessage(msg);
		} catch (e) {
			console.error(e);
		}

		return message;
	}

	sendTyping({
		isTyping,
		content,
		conversationId,
		userId,
	}: SendTypingServiceParams) {
		if (!isTyping) return;
		const chatData = this.storage.getConversation(conversationId)?.[0]?.data;
		chatData?.chat?.typing(chatData?.currentThread, content);
	}

	// You must call doStateUpdate after calling this one or more times
	prependMessage(
		message: ChatMessage<MessageContentType, any>,
		conversationId: ConversationId,
	): ChatMessage<MessageContentType> {
		if (conversationId in this.storage.getState().messages) {
			var updated = false;
			// In general we shouldn't need to prepend a message that is already there,
			// but things happen and some users have reported it is occuring, so guard
			this.storage.getState().messages[conversationId]?.forEach((g) => {
				const [existingMessage, idx] = g.getMessage(message.id);
				if (existingMessage) {
					g.replaceMessage(message, idx as number);
					updated = true;
				}
			});

			if (updated) return message;

			const groups = this.storage.getState().messages[conversationId];

			const firstGroup = groups[0];

			if (
				firstGroup?.messages?.length < 5 &&
				firstGroup?.sender?.id === message.sender.id &&
				(firstGroup?.messages?.[0] as any)?.data?.threadId ===
					message.data?.threadId
			) {
				// Add message to group

				firstGroup.messages.unshift(message);
				return message;
			}
		}

		const group = new MessageGroup({
			id: this.storage.groupIdGenerator(),
			sender: message.sender,
			direction: message.direction,
		});

		group.addMessage(message);

		this.storage.getState().messages[conversationId] =
			conversationId in this.storage.getState().messages
				? [group].concat(this.storage.getState().messages[conversationId])
				: [group];

		const [user] = this.storage.getUser(message.sender.id);
		if (!user) {
			const participant = this.storage
				.getConversation(conversationId)?.[0]
				?.data?.chat.getParticipantDetails(message.sender.id);
			this.storage.addUser(
				new User({
					id: message.sender.id,
					presence: new Presence({
						status: UserStatus.Unknown,
						description: "",
					}),
					firstName: "",
					lastName: "",
					username: participant.displayName,
					email: "",
					avatar: participant.photoUri ?? participant.placeholderUri,
					bio: "",
					data: participant,
				}),
			);
		}

		return message;
	}

	loadMessagesBefore(conversationId: string) {
		if (this.loadingMessagesFor.has(conversationId)) return;
		this.loadingMessagesFor.add(conversationId);

		const message =
			this.storage.getState().messages[conversationId]?.[0]?.messages?.[0];
		const messageId = message?.id;
		const messageTime = message?.createdTime;
		const conversationData =
			this.storage.getConversation(conversationId)?.[0]?.data;
		const chat: snikket.Chat = conversationData?.chat;
		chat?.getMessagesBefore(
			messageId,
			messageTime?.toISOString(),
			(messages) => {
				messages.reverse().forEach((message) => {
					this.prependMessage(this.useMessage(message), conversationId);
				});
				if (!messageId && !messageTime) conversationData.loadedToEnd = true;
				this.loadingMessagesFor.delete(conversationId);
				if (messages.length > 0) this.doStateUpdate();
			},
		);
	}

	loadMessagesAfter(conversationId: string) {
		if (this.loadingMessagesFor.has(conversationId)) return;

		const conversationMessageGroups =
			this.storage.getState().messages[conversationId] || [];
		const conversationMessages =
			conversationMessageGroups[conversationMessageGroups.length - 1]
				?.messages || [];
		const message = conversationMessages[conversationMessages.length - 1];
		const messageData = message?.data || { versions: [] };
		const messageId =
			(messageData?.versions || []).length > 0
				? messageData.versions[0].serverId
				: message?.id;
		const messageTime = message?.createdTime;
		if (!messageId) return; // Can't get after nothing that's just beginning of time
		const conversation = this.storage.getConversation(conversationId)?.[0];
		if (conversation?.data?.loadedToEnd) return;
		this.loadingMessagesFor.add(conversationId);
		const chat: snikket.Chat = conversation?.data?.chat;
		chat?.getMessagesAfter(
			messageId,
			messageTime?.toISOString(),
			(messages) => {
				messages.forEach((message) => {
					if (message.versions.length < 1) {
						this.storage.addMessage(
							this.useMessage(message),
							conversationId,
							false,
						);
					}
				});
				if (messages.length === 0 && conversation?.data)
					conversation.data.loadedToEnd = true;
				this.loadingMessagesFor.delete(conversationId);
				if (messages.length > 0) this.doStateUpdate();
			},
		);
	}

	loadMessagesAround(
		conversationId: string,
		messageId: string,
		messageTime: string,
		callback?: () => void,
	) {
		if (this.loadingMessagesFor.has(conversationId)) {
			return;
		}
		this.loadingMessagesFor.add(conversationId);

		const conversation = this.storage.getConversation(conversationId)?.[0];
		if (!conversation) {
			this.loadingMessagesFor.delete(conversationId);
			throw new Error(
				"Tried to load messages into a nonexistent conversation " +
					conversationId,
			);
		}
		conversation.data = { ...conversation?.data, loadedToEnd: false };
		this.storage.removeMessagesFromConversation(conversationId);

		const chat: snikket.Chat = conversation.data?.chat;
		chat?.getMessagesAround(messageId, messageTime, (messages) => {
			messages.reverse().forEach((message) => {
				this.prependMessage(this.useMessage(message), conversationId);
			});
			this.loadingMessagesFor.delete(conversationId);
			this.storage
				.getState()
				.messages[conversationId]?.forEach((group, idx) => {
					const [m] = group.getMessage(messageId);
					if (m) conversation.data.jumpToIndex = idx;
				});
			delete conversation.data.scrollRange;
			callback ? callback() : this.doStateUpdate();
		});
	}

	acceptCall(conversationId: string) {
		const conversation = this.storage.getConversation(conversationId)?.[0];
		if (!conversation) {
			setTimeout(() => this.acceptCall(conversationId), 500);
			return;
		}
		conversation.data.chat.acceptCall();
	}

	jumpTo(conversationId: string, messageId: string, messageTime: string) {
		const conversation = this.storage.getConversation(conversationId)?.[0];
		if (!conversation) {
			setTimeout(
				() => this.jumpTo(conversationId, messageId, messageTime),
				500,
			);
			return;
		}
		if (!messageId && !messageTime) {
			this.storage.setActiveConversation(conversationId);
			this.doStateUpdate();
		} else {
			this.loadMessagesAround(conversationId, messageId, messageTime, () => {
				this.storage.setActiveConversation(conversationId);
				this.doStateUpdate();
			});
		}
	}

	useMessage(message: snikket.ChatMessage) {
		let status = MessageStatus.DeliveredToCloud;
		if (message.status === snikket.MessageStatus.MessagePending)
			status = MessageStatus.Sent;
		if (message.status === snikket.MessageStatus.MessageDeliveredToServer)
			status = MessageStatus.DeliveredToCloud;
		if (message.status === snikket.MessageStatus.MessageDeliveredToDevice)
			status = MessageStatus.DeliveredToDevice;
		if (message.status === snikket.MessageStatus.MessageFailedToSend)
			status = MessageStatus.Pending;
		return new ChatMessage({
			id: message.serverId || message.localId, // Sometimes we don't know the serverId of a sent message
			status: status,
			sender: new Sender({ id: message.senderId, type: SenderType.User }),
			direction: message.isIncoming()
				? MessageDirection.Incoming
				: MessageDirection.Outgoing,
			content: { body: message.html() },
			contentType: MessageContentType.TextHtml,
			createdTime: new Date(message.timestamp),
			data: message as any,
		});
	}

	logout(completely: boolean) {
		this.xmppClient.logout(completely);

		let unsub = async () => {};
		if (navigator.serviceWorker) {
			unsub = async () => {
				const serviceWorkerRegistration = await navigator.serviceWorker.ready;
				const sub =
					await serviceWorkerRegistration.pushManager.getSubscription();
				if (!sub) return;
				await sub.unsubscribe();
			};
		}

		unsub().then(() => setTimeout(() => window.location.reload(), 2000));
	}

	prepareAttachment(file: File) {
		return new Promise((resolve, reject) => {
			this.xmppClient.prepareAttachment(file, (attachment) =>
				attachment ? resolve(attachment) : reject(null),
			);
		});
	}

	findAvailableChats(
		q: string,
		callback: (q: string, chats: Array<snikket.AvailableChat>) => void,
	) {
		this.xmppClient.findAvailableChats(q, callback);
	}

	startChat(availableChat: snikket.AvailableChat, trust: boolean) {
		const chat = this.xmppClient.startChat(availableChat);
		if (trust) chat.setTrusted(trust);
		chat.bookmark();
		this.storage.setActiveConversation(chat.chatId);
		this.doStateUpdate();
	}

	// The ChatProvider registers callbacks with the service.
	// These callbacks are necessary to notify the provider of the changes.
	// For example, when your service receives a message, you need to run an onMessage callback,
	// because the provider must know that the new message arrived.
	// Here you need to implement callback registration in your service.
	// You can do it in any way you like. It's important that you will have access to it elsewhere in the service.
	on<T extends ChatEventType, H extends ChatEvent<T>>(
		evtType: T | "callAudioTrack",
		evtHandler: ChatEventHandler<T, H>,
	) {
		const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`;

		if (key in this.eventHandlers) {
			this.eventHandlers[key] = evtHandler;
		}
	}

	// The ChatProvider can unregister the callback.
	// In this case remove it from your service to keep it clean.
	off<T extends ChatEventType, H extends ChatEvent<T>>(
		evtType: T,
		eventHandler: ChatEventHandler<T, H>,
	) {
		// Just use the old one on race condition, better than dropping events
		/*const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`;
		if (key in this.eventHandlers) {
			this.eventHandlers[key] = () => {};
		}*/
	}
}
